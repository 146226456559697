/* eslint-disable max-len */
export async function getApptByPy(py) {
  if (!py) {
    throw new Error(`Error: please specify the the PY! status: ${response.status}`);
  }
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
       'api/appointmentReportsByPy/'+ py,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error: No Appointments In ${py}! status: ${response.status}`);
  }
  const data = await response.json();
  // eslint-disable-next-line max-len
  return data;
}
export async function getAllPy() {
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
       'api/getAllPy/',
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error: No PY's to GET! status: ${response.status}`);
  }
  const data = await response.json();
  // eslint-disable-next-line max-len
  return data;
}


export async function deleteAppt(id) {
  const response = await
  fetch(`${window.env.REACT_APP_API_URL}`+
  `api/deleteAppointment/${id}`,
  {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
    }
  });
  if (!response.ok) {
    throw new Error(`Error: Appt could not be marked as deleted! status: ${response.status}`);
  }
  location.reload();
}

// Use as reference
export async function fetchAppointments() {
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
  'api/appointmentsNotDeleted/',
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error: No appointments to GET! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}

export async function fetchAppointmentReports() {
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
  'api/appointmentReports',
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error: No appointments to GET! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}

export async function fetchApptsByDateRange(startDate, endDate) {
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
  `api/allApptsByDateRange/${startDate}/${endDate}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error: No appointments to GET! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}


export async function fetchCanceledAppts(startDate, endDate) {
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
  `api/canceledAppts/${startDate}/${endDate}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error: No appointments to GET! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}

export async function fetchCompleteAppts(startDate, endDate) {
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
  `api/completeApptsByDateRange/${startDate}/${endDate}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error: No appointments to GET! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}

export async function apptReportsByIdAndDate(id, date) {
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
  `api/completeApptsByDateRange/${id}/${date}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error: No appointments to GET! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}

export async function getApptsByNavigator(id, startDate, endDate) {
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
  `api/allApptReportsByNavAndDateRange/${id}/${startDate}/${endDate}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error: No appointments for this navigator to GET! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}

export async function getApptsByLocation(id, startDate, endDate) {
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
  `api/allApptsByLocationAndDateRange/${id}/${startDate}/${endDate}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error: No appointments for this location to GET! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}


export async function getNavigatorName(id) {
  if (!id) {
    throw new Error(`Error: please specify the the Navigator Id! status: ${response.status}`);
  }
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
  'api/users/' + id,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error:There are no users with specified id:${id}! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}


export async function getApptBtw(startDate, endDate) {
  console.log('🚀 ~ file: AppointmentService.jsx:97 ~ getApptBtw ~ endDate:', endDate);
  console.log('🚀 ~ file: AppointmentService.jsx:97 ~ getApptBtw ~ startDate:', startDate);
  if (!startDate || !endDate) {
    throw new Error(`Error: please specify startDate && endDate Id! status: ${response.status}`);
  }
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
  'api/allApptsByDateRange/' + startDate + '/' + endDate,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error:There are no appointments in range specified! status: ${response.status}`);
  }
  const data = await response.json();
  console.log('🚀 ~ file: AppointmentService.jsx:113 ~ getApptBtw ~ data:', data);
  return data;
}

export async function getApptById(id) {
  const response = await fetch(`${window.env.REACT_APP_API_URL}api/appointmentById/${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
    });

  if (!response.ok) {
    throw new Error(`Error:There are no appointments with that id! status: ${response.status}`);
  }
  const data = await response.json();
  console.log('🚀 ~ file: AppointmentService.jsx:113 ~ getApptBtw ~ data:', data);
  return data;
}


/* eslint-disable react/prop-types */
import MakeAppointmentClient from '../components/MakeAppointmentClient';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Appointments from './../components/Appointments';
import moment from 'moment';


function SearchedApptsClient({ county, typeArr, setAppt }) {
  // const [types] = useState(appt.type.split(','));
  const [appointments, setAppointments] = useState([]);

  const navigate = useNavigate();

  const navigateToApptConfirmation = () => {
    navigate('/confirmappt');
  };

  const navigateToMakeAppt = () => {
    navigate('/makeappointment');
  };

  useEffect(() => {
    const headers = { 'Content-Type': 'application/json'
    };
    fetch(`${window.env.REACT_APP_API_URL}`+
    'api/allAvailableAppointmentsByCountyAndType/' +
    county + '/' + typeArr.toString(),
    { headers } )
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAppointments(data);
      });
  }, []);
  return (
    <div className="container-fluid">
      <div className="h1 text-center mt-5 text-warning">
        Make an Appointment</div>
      <div className="d-flex justify-content-center">
        <div className="card cardShadow mt-5 w-50 mb-5">
          <div className="card-body">
            <div className="row">
              <div className="h5 col-8 ms-3">
                {/* pull county from fetch here */}
                We found these appointments in {county} county
              </div>
              <div className="col-3 d-flex justify-content-end pe-4">
                <button className='btn btn-warning'
                  onClick={() => {
                    navigateToMakeAppt();
                    location.reload();
                  }}>
                  Search Again
                </button>
              </div>
            </div>
            <div className="row mt-4">
              <p className='ms-3 h6 text-secondary'>Schedule an appointment</p>
              <hr />
            </div>
            {/* map through appointments here */}
            {appointments.map((appt, id) =>
              <div key={id} className="row p-3">
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <tr className='h4'>
                        {moment(appt.appt_date).format('MMM')}
                      </tr>
                      <tr>
                        <td className='h4 col-1'>
                          {moment(appt.appt_date).format('DD')}
                        </td>
                        <td className='col-7'>
                          {appt.appt_time} Appointment
                        </td>
                        <td className='col-3'>
                          <span key={id} className=' h3 badge badge-blue m-1
                       rounded-pill'>
                            {appt.type}</span>
                        </td>
                      </tr>
                      <tr>
                        <td ></td>
                        <td>
                          <div className="d-flex justify-content-end">
                            <img src="../images/wheelchair.svg" alt="" />
                        &nbsp;&nbsp;
                            <img src="../images/blind.svg" alt="" />&nbsp;&nbsp;
                            <img src="../images/deaf.svg" alt="" />
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end c'>
                            <button className='btn btn-warning ms-3 me-4
                             text-nowrap'
                            onClick={() => {
                              setAppt(appt);
                              navigateToApptConfirmation();
                            }}
                            >SCHEDULE THIS</button>
                          </div>
                        </td>
                      </tr>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

  );
}

export default SearchedApptsClient;

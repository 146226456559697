/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useState, useEffect } from 'react';
import ConfirmApptModal from '../components/ConfirmApptModal';
import emailjs from '@emailjs/browser';
import { getLocationById } from '../services/LocationService';
import { array } from 'prop-types';

function ApptConfirmation({ appt }) {
  const [clientFn, setClientFn] = useState('');
  const [clientLn, setClientLn] = useState('');
  const [types] = useState(appt.type.split(','));
  const [prefType, setPrefType] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [adminEmails, setAdminEmails] = useState('');
  const [loc, setLoc] = useState([]);

  useEffect(() => {
    const headers = { 'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('AccessToken')
    };
    fetch(`${window.env.REACT_APP_API_URL}`+'api/getAdminEmails',
      { headers } )
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAdminEmails(data);
      });
  }, []);


  const submitSchedule = (e, id, name, prefType) => {
    e.preventDefault();
    fetch(`${window.env.REACT_APP_API_URL}`+
      'api/scheduleAppointment/'+ id + '/' + name + '/' +
      prefType,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    clientEmail();
    adminEmail();
    setTimeout(navigateToMakeAppt, 5000);
  };

  useEffect(() => {
    (async () => {
      const res = await getLocationById(appt.location_id);
      setLoc(res);
    })();
  }, []);


  function clientEmail() {
    const templateParams = {
      clientEmail: email,
      clientPhone: phone,
      clientPrefType: prefType,
      clientFn: clientFn,
      clientLn: clientLn,
      apptDate: moment(appt.appt_date).format('MMMM Do YYYY'),
      apptTime: appt.appt_time,
      apptLocation: loc.location_name,
      apptAddress: (loc.location_type === 'Physical' ? loc.physical_address + ', ' + loc.city + ', ' +
      loc.state + ', ' + loc.zip : loc.virtual_address + ', ' + loc.city + ', ' +
      loc.state + ', ' + loc.zip)
    };
    emailjs.send(`${window.env.REACT_APP_SERVICE_ID}`
      , 'Client_Template', templateParams,
      `${window.env.REACT_APP_PUBLIC_KEY}`)
      .then(function(response) {
        ('email sent !', response.status, response.text);
      }, function(error) {
        ('email not sent!', error);
      });
  }

  function adminEmail() {
    const templateParams = {
      admins: (adminEmails.join(';')),
      clientEmail: email,
      clientPhone: phone,
      clientPrefType: prefType,
      clientFn: clientFn,
      clientLn: clientLn,
      apptDate: moment(appt.appt_date).format('MMMM Do YYYY'),
      apptTime: appt.appt_time,
      apptLocation: loc.location_name,
      apptAddress: (loc.location_type === 'Physical' ? loc.physical_address + ', ' + loc.city + ', ' +
      loc.state + ', ' + loc.zip : loc.virtual_address + ', ' + loc.city + ', ' +
      loc.state + ', ' + loc.zip)
    };
    emailjs.send(`${window.env.REACT_APP_SERVICE_ID}`
      , 'Admin_Template', templateParams,
      `${window.env.REACT_APP_PUBLIC_KEY}`)
      .then(function(response) {
        ('email sent !', response.status, response.text);
      }, function(error) {
        ('email not sent!', error);
      });
  }


  function formatPhoneNumber(inputValue) {
    if (!inputValue) return inputValue;

    const phoneNumber = inputValue.replace(/[^\d]/g, '');

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const handleInput = e => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setInputValue(formattedPhoneNumber);
  };


  const navigate = useNavigate();

  const navigateToSearchResults = () => {
    navigate('/searchresults');
  };

  const navigateToMakeAppt = () => {
    navigate('/makeappointment');
    location.reload();
  };


  return (
    <div className="silverBackground">
      <div className="container-fluid mt-5">
        <div className="h1 text-center my-5 text-warning">
          Make an Appointment</div>
        <div className="d-flex justify-content-center">
          <div className="card cardShadow w-50 mb-5">
            <div className="card-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 h4 m-2">
                    You have chosen&nbsp;
                  </div>
                  <div className="col-5 d-flex justify-content-end">
                    <button className="btn btn-warning"
                      onClick={navigateToSearchResults}>&lt;
                      GO BACK</button>
                  </div>

                  <div className="row mt-4">
                    <div className="col-7 h5 ms-4">
                      {moment(appt.appt_date).format('MMMM Do')}

                    </div>
                    <div className="col-4 d-flex justify-content-end">
                      {types.map((type, id) =>
                        <span key={id} className='h3 badge
                         badge-blue rounded-pill m-1'>
                          {type}</span>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-4 ms-4">
                      Appointment at {appt.appt_time}
                    </div>
                    <div className="col-3 d-flex justify-content-end">
                      <img src="../images/wheelchair.svg" alt="" />&nbsp;&nbsp;
                      <img src="../images/blind.svg" alt="" />&nbsp;&nbsp;
                      <img src="../images/deaf.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div id='map'></div>
                <div className="row h5 mt-3 ms-1 text-secondary">
                  SCHEDULE YOUR APPOINTMENT
                </div>
                <div className='ms-2 row h4'>
                  {loc.location_name}
                </div>
                <div className="row ms-1">
                  <div className="col-8">
                  </div>
                  <hr />
                  {loc.location_type === 'Physical' ?
                    <div className="ms-2">{loc.physical_address}</div>:
                    <div className="ms-2">{loc.virtual_address}</div>
                  }
                  <div className="ms-2">{loc.city}, {loc.state}
                    {loc.zip}</div>
                </div>
              </div>
            </div>

            <form
              onSubmit={e => {
                submitSchedule(e, appt.id,
                  (clientFn + ' ' + clientLn),
                  prefType);
              }}>
              <div className="row">
                <div className="col-3 m-4">
                  <input
                    type='text'
                    className='form-control ms-4 cardShadow'
                    id='clientName'
                    data-testid='nameInput'
                    placeholder='First Name'
                    required
                    onChange={e => setClientFn(e.target.value)}
                  />
                </div>
                <div className="col-3 my-4">
                  <input
                    type='text'
                    className='form-control ms-4 cardShadow'
                    id='clientName'
                    data-testid='nameInput'
                    placeholder='Last Name'
                    required
                    onChange={e => setClientLn(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3 ms-4 mb-4">
                  <input
                    type='text'
                    className='form-control ms-4 cardShadow'
                    id='clientName'
                    data-testid='nameInput'
                    placeholder='Email'
                    required
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-3 ms-4 mb-4">
                  <input
                    type='text'
                    className='form-control ms-4 cardShadow'
                    id='clientName'
                    data-testid='nameInput'
                    placeholder='Phone Number'
                    required
                    maxLength={12}
                    value={inputValue}
                    onChange={e => {
                      handleInput(e);
                      setPhone(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <select
                // required
                  className='form-select form-control ms-5 mb-4 w-50 cardShadow'
                  aria-label='select county'
                  data-testid='apptCounty'
                  onChange={e => setPrefType(e.target.value)}
                >
                  <option value=''>
                    &nbsp;&nbsp;Select Preferred Appointment Type*
                  </option>
                  {types.map((type, id) =>
                    <option key={id} value={type}>&nbsp;&nbsp;{type}</option>
                  )}
                </select>
              </div>
              <div className="row ms-3">
                <div className="col ms-4">
                  <p>By scheduling this appointment
                    you consent to the Privacy Policy.</p>
                </div>
              </div>
              <div className="row ms-4">
                <div className="col ms-4">
                  <p>*MSG and DATA rates may apply</p>
                </div>
              </div>
              {clientFn != '' &&
                clientLn != '' && phone != '' && email != '' &&
                prefType != '' &&

                  <div className="row d-flex justify-content-center">
                    <button className='btn btn-warning w-75 mb-5'
                      data-bs-toggle='modal'
                      data-bs-target={'#confirmApptModal' + appt.id}
                      type='submit'>
                      SCHEDULE THIS APPOINTMENT
                    </button>

                    <ConfirmApptModal appt={appt} phone={phone}
                      email={email} clientFn={clientFn} clientLn={clientLn}
                      prefType={prefType}/>

                  </div>
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}


export default ApptConfirmation;

import moment from 'moment';
import { useState, useEffect } from 'react';


function ApptScheduled(appt, email, phone) {
  const { schedAppt, setSchedAppt } = useState([]);
  useEffect(() => {
    const headers = { 'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('AccessToken')
    };
    fetch(`${window.env.REACT_APP_API_URL}`+
    'api/appointmentById/' +
        appt.appt.id,
    { headers } )
      .then(res => {
        return res.json();
      })
      .then(data => {
        setSchedAppt(data);
      });
  }, []);


  return (
    <div className="container-fluid mt-5">
      <div className="h1 text-center my-5 text-warning">
        Appointment Scheduled</div>
      <div className="d-flex justify-content-center">
        <div className="card cardShadow w-50 mb-5">
          <div className="card-body">
            <div className="h4">Your appointment is now scheduled</div>
            <div className="h6 text-secondary ms-3">You will receive a
              phone and email confirmation</div>
            <div className="row mt-4 ms-4 mb-4">
              <div className="col-2">
                {moment(appt.appt.appt_date).format('MMMM Do YYYY')}</div>
              <div className="col-4">{appt.appt.appt_time}</div>
            </div>
            <div className="h4 text-secondary mt-5">
              {appt.appt.client_name}</div>
          </div>
          <hr className="mx-4"/>
          {/* <div className="p ms-5">{email}</div>
          <div className="p ms-5 mb-5">{phone}</div> */}
        </div>
      </div>
    </div>
  );
}

export default ApptScheduled;

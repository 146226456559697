/* eslint-disable max-len */
function OverlapModal() {
  return (
    <div className="row text-center">
      <div className="col">
        {/* <i className="bi bi-check-circle-fill text-success display-2"></i> */}
        <br />
        <h1 className="fw-bold mt-3">Scheduling Error</h1>
        <div className="h4">Schedules are not allowed to overlap.</div>
        <div className="h4">Please check the shifts you scheduled and try again.</div>
      </div>
    </div>
  );
}

export default OverlapModal;

/* eslint-disable react/prop-types */
import MakeAppointmentClient from '../components/MakeAppointmentClient';
import { useState } from 'react';


function AppointmentClientSide({ county, typeArr, setTypeArray, setCounty }) {
  return (
    <div className="container-fluid">
      <div className="h1 text-center mt-5 text-warning">
        Make an Appointment</div>
      <MakeAppointmentClient typeArr={typeArr} county={county}
        setTypeArray={setTypeArray}
        setCounty={setCounty} />
    </div>
  );
}

export default AppointmentClientSide;

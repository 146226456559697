/* eslint-disable max-len */
export async function fetchAllLocations() {
  // eslint-disable-next-line max-len
  const res = await fetch(`${window.env.REACT_APP_API_URL}api/locationsNotDeleted`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
    });

  if (!res.ok) {
    throw new Error(`Error: No locations to GET! status: ${res.status}`);
  }
  const data = await res.json();
  return data;
}

export async function getDeletedLocations() {
  const response = await fetch(`${window.env.REACT_APP_API_URL}api/getDeletedLocations`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });
  if (!response.ok) {
    throw new Error(`Error: There are no locations! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}

export async function getLocationById(id) {
  if (!id) {
    throw new Error(`Error: please specify the the Location Id! status:
     ${response.status}`);
  }
  const response = await fetch(`${window.env.REACT_APP_API_URL}` +
  'api/locationById/' + id,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
  });

  if (!response.ok) {
    throw new Error(`Error:There are no locations with specified id:
    ${id}! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}

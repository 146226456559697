/* eslint-disable max-len */
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function SessionExpiredModal() {
  const [showModal, setShowModal] = useState(true);

  const handleOkayClick = () => {
    setShowModal(false);
    window.location.href = '/logout';
  };

  return (
    <Modal show={showModal} backdrop="static" keyboard={false}>
      <Modal.Header className='text-center d-flex justify-content-center align-items-center border-secondary'>
        <Modal.Title className='display-7'>Session Expired</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center d-flex justify-content-center align-items-center'>
        <p className='text-center h6 p-3'>Your session has expired. Please log in again.</p>
      </Modal.Body>
      <Modal.Footer className='text-center d-flex justify-content-center align-items-center border-secondary'>
        <Button variant="primary" onClick={handleOkayClick}>RETURN TO LOGIN</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SessionExpiredModal;

/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Navigator from './Navigator';
import OverlapModal from './OverlapModal';

const AddShiftForm = ({ user, weekNumber, projYear }) => {
  const [shifts, setShifts] = useState([
    { day: 'Saturday', location: '', start_time: 'OFF', end_time: 'OFF', break_start_time: '', break_end_time: '' },
    { day: 'Sunday', location: '', start_time: 'OFF', end_time: 'OFF', break_start_time: '', break_end_time: '' },
    { day: 'Monday', location: '', start_time: 'OFF', end_time: 'OFF', break_start_time: '', break_end_time: '' },
    { day: 'Tuesday', location: '', start_time: 'OFF', end_time: 'OFF', break_start_time: '', break_end_time: '' },
    { day: 'Wednesday', location: '', start_time: 'OFF', end_time: 'OFF', break_start_time: '', break_end_time: '' },
    { day: 'Thursday', location: '', start_time: 'OFF', end_time: 'OFF', break_start_time: '', break_end_time: '' },
    { day: 'Friday', location: '', start_time: 'OFF', end_time: 'OFF', break_start_time: '', break_end_time: '' }
  ]);

  const timeArr = [
    'OFF', '04:00 AM', '04:30 AM', '05:00 AM', '05:30 AM', '06:00 AM', '06:30 AM',
    '07:00 AM', '07:30 AM', '08:00 AM', '08:30 AM', '09:00 AM', '09:30 AM',
    '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM',
    '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM',
    '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM',
    '7:00 PM', '7:30 PM', '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM'
  ];
  const breakTimeArr = [
    '04:00 AM', '04:30 AM', '05:00 AM', '05:30 AM', '06:00 AM', '06:30 AM',
    '07:00 AM', '07:30 AM', '08:00 AM', '08:30 AM', '09:00 AM', '09:30 AM',
    '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM',
    '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM',
    '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM',
    '7:00 PM', '7:30 PM', '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM'
  ];


  const userId = user;
  // const [weekNumber, setWeekNumber] = useState(currentWeek);
  const [locations, setLocations] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [showOverlapModal, setShowOverlapModal] = useState(false);

  const getLocationByName = (objArr, targetName) => {
    for ( let obj of objArr ) {
      if (obj.location_name === targetName) {
        return obj;
      }
    }
    return null;
  };

  async function fetchAllLocations() {
    // eslint-disable-next-line max-len
    const res = await fetch(`${window.env.REACT_APP_API_URL}api/locationsNotDeleted`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('AccessToken') }
      });

    if (!res.ok) {
      throw new Error(`Error: No locations to GET! status: ${res.status}`);
    }
    const data = await res.json();
    setLocations(data);
  }
  useEffect(() => {
    fetchAllLocations();
  }, []);

  const onSubmit = shifts => {
    console.log(shifts);
  };

  const handleInputChange = (event, index, field) => {
    const newShifts = [...shifts];
    newShifts[index][field] = event.target.value;
    setShifts(newShifts);
  };

  const handleAddShift = day => {
    const newShifts = [...shifts];
    const index = newShifts.findIndex(shift => shift.day === day);
    newShifts.splice(index + 1, 0, {
      day: day,
      location: '',
      start_time: '',
      end_time: '',
      break_start_time: '',
      break_end_time: '',
      removeable: true
    });
    setShifts(newShifts);
  };

  const handleRemoveShift = index => {
    if (index !== -1) {
      const newShifts = [...shifts];
      newShifts.splice(index, 1);
      setShifts(newShifts);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (user === 0) {
      alert('Please select a navigator');
      return;
    }
    // Filter out breaks
    const newShifts = shifts.filter(shift => shift.start_time !== 'BREAK' && shift.end_time !== 'BREAK' && shift.start_time && shift.end_time);

    // Check for overlapping shifts
    let overlapFound = false;
    const sortedShifts = [...newShifts].sort((a, b) => {
      if (a.day < b.day) return -1;
      if (a.day > b.day) return 1;
      if (a.start_time < b.start_time) return -1;
      if (a.start_time > b.start_time) return 1;
      if (a.break_start_time < b.break_start_time) return -1;
      if (a.break_start_time > b.break_start_time) return 1;
      return 0;
    });
    sortedShifts.forEach((shift, i) => {
      if (i < sortedShifts.length - 1) {
        const nextShift = sortedShifts[i + 1];
        if (shift.day === nextShift.day && moment(shift.end_time, 'hh:mm A').isAfter(moment(nextShift.start_time, 'hh:mm A'))) {
          overlapFound = true;
        }
      }
    });

    if (overlapFound) {
      setShowOverlapModal(true);
      return;
    }

    const shiftsToSave = newShifts.map(shift => ({
      ...shift,
      user_id: userId,
      location_id: shift.location,
      week_number: weekNumber,
      proj_year: projYear,
      break_start_time: shift.break_start_time,
      break_end_time: shift.break_end_time
    }));

    try {
      const response = await fetch(`${window.env.REACT_APP_API_URL}api/addShifts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
        },
        body: JSON.stringify(shiftsToSave)
      });

      if (!response.ok) {
        throw new Error('Failed to save shifts');
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
    onSubmit(shiftsToSave);
    window.location.reload();
  };

  const handleChange = (event, index) => {
    const newShifts = [...shifts];
    const selectedValue = event.target.value;
    const selectedShift = newShifts[index];

    if (selectedValue === 'early') {
      selectedShift.start_time = '08:00 AM';
      selectedShift.end_time = '5:00 PM';
    } else if (selectedValue === 'mid') {
      selectedShift.start_time = '09:00 AM';
      selectedShift.end_time = '6:00 PM';
    } else if (selectedValue === 'late') {
      selectedShift.start_time = '11:00 AM';
      selectedShift.end_time = '8:00 PM';
    } else if (selectedValue === 'off') {
      selectedShift.start_time = 'OFF';
      selectedShift.end_time = 'OFF';
    }

    setShifts(newShifts);
  };

  return (
    <>
      <hr className='m-4'/>
      <form onSubmit={handleSubmit}>
        <table className='table table-striped'>

          <thead>
            <tr>
              <th scope="col">Day</th>
              <th scope="col">Shifts</th>
              <th scope="col">Location</th>
              <th scope="col">Shift Start</th>
              <th scope="col">Shift End</th>
              <th scope="col">Break Start</th>
              <th scope="col">Break End</th>
              <th scope='col'>Add Shift</th>
            </tr>
          </thead>
          {shifts.map((shift, index) => (
            <tbody key={index}>
              <tr>
                <td>
                  <div className="h5">{shift.day}</div>
                </td>
                <td className='d-flex'>
                  <input
                    type="radio"
                    id={`early${shift.day}`}
                    name={`${shift.day}_shift`}
                    value="early"
                    onChange={event => handleChange(event, index)}
                  />
                  <label htmlFor={`early${shift.day}`} className='me-1'>
                    Early
                  </label>
                  <input
                    type="radio"
                    id={`mid${shift.day}`}
                    name={`${shift.day}_shift`}
                    value="mid"
                    onChange={event => handleChange(event, index)}
                  />
                  <label htmlFor={`mid${shift.day}`} className="me-1">
                    Mid
                  </label>
                  <input
                    type="radio"
                    id={`late${shift.day}`}
                    name={`${shift.day}_shift`}
                    value="late"
                    onChange={event => handleChange(event, index)}
                  />
                  <label htmlFor={`late${shift.day}`} className="me-1">
                    Late
                  </label>
                  <input
                    type="radio"
                    id={`off${shift.day}`}
                    name={`${shift.day}_shift`}
                    value="off"
                    checked={shift.start_time === 'OFF'}
                    onChange={event => handleChange(event, index)}
                  />
                  <label htmlFor={`off${shift.day}`} className='me-1'>
                    Off
                  </label>
                </td>
                <td>
                  <select className='form-select'
                    onChange={event => handleInputChange(event, index, 'location')}
                  >
                    <option value="">Select</option>
                    {locations
                      .slice() // Create a shallow copy to avoid modifying the original array
                      .sort((a, b) => {
                        if (a.id === 1) return -1; // Location with ID 1 comes first
                        if (b.id === 1) return 1; // Location with ID 1 comes first
                        return a.location_name.localeCompare(b.location_name); // Sort alphabetically
                      })
                      .map((loc, id) => (
                        <option key={id} value={loc.id}>{loc.location_name}</option>
                      ))}
                  </select>
                </td>

                <td>
                  <select
                    className='form-select'
                    type="text"
                    value={shift.start_time}
                    onChange={event => handleInputChange(event, index, 'start_time')}
                  >
                    <option value="">Select...</option>
                    {timeArr.map((timeslot, id) =>
                      <option key={id}>{timeslot}</option>
                    )}
                  </select>
                </td>
                <td>
                  <select
                    className='form-select'
                    type="text"
                    value={shift.end_time}
                    onChange={event => handleInputChange(event, index, 'end_time')}
                  >
                    <option value="">Select...</option>
                    {timeArr.map((timeslot, id) =>
                      <option key={id}>{timeslot}</option>
                    )}
                  </select>
                </td>
                <td>
                  <select
                    className='form-select'
                    type="text"
                    value={shift.break_start_time}
                    onChange={event => handleInputChange(event, index, 'break_start_time')}
                  >
                    <option value="">Select...</option>
                    {breakTimeArr.map((timeslot, id) =>
                      <option key={id}>{timeslot}</option>
                    )}
                  </select>
                </td>
                <td>
                  <select
                    className='form-select'
                    type="text"
                    value={shift.break_end_time}
                    onChange={event => handleInputChange(event, index, 'break_end_time')}
                  >
                    <option value="">Select...</option>
                    {breakTimeArr.map((timeslot, id) =>
                      <option key={id}>{timeslot}</option>
                    )}
                  </select>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    {shift.removeable !== true && (
                      <button type="button"
                        className='btn btn-outline-primary'
                        onClick={() => handleAddShift(shift.day, index)}>
                        Add Shift
                      </button>
                    )}
                    {shift.removeable === true && (
                      <button type="button"
                        className='btn btn-outline-primary'
                        onClick={() => handleRemoveShift(index)}>
                        Remove Shift
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          ))}

        </table>
        <br />
        <div className="mb-3 d-flex justify-content-center">
          <button type="submit"
            className='btn btn-primary col-6'>ADD THIS SCHEDULE</button>
        </div>
      </form>
      {showOverlapModal && <OverlapModal />}
    </>
  );
};

export default AddShiftForm;

/* eslint-disable max-len */
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import SessionExpiredModal from './components/SessionExpiredModal';
import AppointmentClientSide from './pages/AppointmentClientSide';
import SearchedApptsClient from './pages/SearchedApptsClient';
import ApptConfirmation from './pages/ApptConfirmation';
import ApptScheduled from './pages/ApptScheduled';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const Schedule = lazy(() => import('./pages/Schedule'));
const Clients = lazy(() => import('./pages/Clients'));
const Settings = lazy(() => import('./pages/Settings'));
const Users = lazy(() => import('./pages/Users'));
const Login = lazy(() => import('./components/Login'));
const ActivitiesView = lazy(() => import('./pages/ActivitiesView'));
const Notifications = lazy(() => import('./pages/Notifications'));
const ClientProfile = lazy(() => import('./pages/ClientProfile'));
const LiveInteractionReport = lazy(() =>
  import('./pages/LiveInteractionReport'));
const FiveWsReportOne = lazy(() => import('./pages/FiveWsReportOne'));
const FiveWsReportTwo = lazy(() => import('./pages/FiveWsReportTwo'));
const FiveWsReportThree = lazy(() => import('./pages/FiveWsReportThree'));
const FiveWsReportFour = lazy(() => import('./pages/FiveWsReportFour'));
const NavDashboard = lazy(() => import('./pages/NavDashboard'));
const LogOut = lazy(() => import('./pages/LogOut'));
const Events = lazy(() => import('./pages/Events'));
const WeeklyStats = lazy(() => import('./pages/WeeklyStats'));
const NavigatorStats = lazy(() => import('./pages/NavigatorStats'));
const ReportsLandingPage = lazy(() => import('./pages/ReportsLandingPage'));
const EventProfile = lazy(() => import('./pages/EventProfile'));
const EventLIReport = lazy(() => import('./pages/EventLIReport'));
const ClientSideRouting = lazy(() => import('./pages/ClientSideRouting'));
const ClientNotes = lazy(() => import('./pages/ClientNotes'));
const NavsClients = lazy(() => import('./pages/NavsClients'));
const ScheduleCard = lazy(() => import('./pages/ScheduleCard'));
const GovReport = lazy(() => import('./pages/GovReport'));
const GovReview = lazy(() => import('./components/GovReview'));
const NavigatorCalendar = lazy(() => import('./pages/NavigatorCalendar'));
const SuperAdminSchedule = lazy(() => import('./pages/SuperAdminSchedule'));
const NavigatorsSchedule = lazy(() => import('./pages/NavigatorsSchedule'));
const Locations = lazy(() => import('./pages/Locations'));
const DeletedClients = lazy(() => import('./pages/DeletedClients'));
const ImportPYs = lazy(() => import('./pages/ImportPYs'));
const DeletedUsers = lazy(() => import('./pages/DeletedUsers'));
const CallListPage = lazy(() => import('./pages/CallListPage'));
const CallListNavigator = lazy(() => import('./pages/CallListNavigator'));
const CallListSpecific = lazy(() => import('./pages/CallListSpecific'));
const NavCallListSpecific = lazy(() => import('./pages/NavCallListSpecific'));
const Counties = lazy(() => import('./pages/Counties'));
const MobileOutreachPage = lazy(() => import('./pages/MobileOutreachPage'));
const MobileOutreachLIReport = lazy(() => import('./components/MobileOutreachLIReport'));
const MobileOutreachProfile = lazy(() => import('./pages/MobileOutreachProfile'));
import AddShiftForm from './components/AddShiftForm';

function App() {
  const [county, setCounty] = useState('');
  const [typeArr, setTypeArray] = useState(['In-Person', 'Virtual', 'Telephone']);
  const [appt, setAppt] = useState({});
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  // Every second checks if token exists and expiration of token if exists
  useEffect(() => {
    const tokenExpirationTimer = setInterval(() => {
      if (window.location.pathname !== '/') {
        const accessToken = localStorage.getItem('AccessToken');
        if (!accessToken) {
          const allowedRoutes = ['/makeappointment', '/confirmappt', '/apptscheduled', '/searchresults'];
          if (!allowedRoutes.includes(window.location.pathname)) {
            window.location.href = '/logout';
          }
        } else {
          const parts = accessToken.split('.');
          const payload = JSON.parse(atob(parts[1]));
          const currentTime = Date.now() / 1000;
          if (payload.exp < currentTime) {
            localStorage.removeItem('AccessToken');
            setIsTokenExpired(true);
            clearInterval(tokenExpirationTimer);
          }
        }
      }
    }, 1000);
  }, []);

  return (
    <>
      {isTokenExpired && <SessionExpiredModal />}
      <Suspense fallback={
        <div className="d-flex justify-content-center
        align-items-center vh-100 silverBackground">
          <div className="spinner-border
        text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      }>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/events" element={<Events />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/deletedclients" element={<DeletedClients />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/weeklystats" element={<WeeklyStats />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users:id" element={<Users />} />
          <Route path="/activities" element={<ActivitiesView />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/notifications:id" element={<Notifications />} />
          <Route path="/clientprofile" element={<ClientProfile />} />
          <Route path="/clientprofile:id" element={<ClientProfile />} />
          <Route path="/eventprofile" element={<EventProfile />} />
          <Route path="/eventprofile:id" element={<EventProfile />} />
          <Route
            path="/liveinteractionreport"
            element={<LiveInteractionReport />}
          />
          <Route path='/event-li-report' element={<EventLIReport />} />
          <Route path='/clientnotes:id' element={<ClientNotes />} />
          <Route path="/fivewsreportone" element={<FiveWsReportOne />} />
          <Route path="/fivewsreporttwo" element={<FiveWsReportTwo />} />
          <Route path="/fivewsreportthree" element={<FiveWsReportThree />} />
          <Route path="/fivewsreportfour" element={<FiveWsReportFour />} />
          <Route path="/navdashboard" element={<NavDashboard />} />
          <Route path="/navconsumerreporting" element={<NavigatorStats />} />
          <Route path="/reports" element={<ReportsLandingPage />} />
          <Route path="/importPYs" element={<ImportPYs />} />
          <Route
            path="/confirmappt"
            element={
              <ApptConfirmation
                county={county}
                typeArr={typeArr}
                setCounty={setCounty}
                appt={appt}
                setAppt={setAppt}
              />
            }
          />
          <Route
            path="/apptscheduled"
            element={
              <ApptScheduled
                county={county}
                typeArr={typeArr}
                setCounty={setCounty}
                appt={appt}
                setAppt={setAppt}
              />
            }
          />
          <Route path="/logout" element={<LogOut />} />
          <Route
            path="/makeappointment"
            element={
              <AppointmentClientSide
                county={county}
                typeArr={typeArr}
                setCounty={setCounty}
                appt={appt}
                setTypeArray={setTypeArray}
              />
            }
          />
          <Route
            path="/searchresults"
            element={
              <SearchedApptsClient
                typeArr={typeArr}
                county={county}
                setCounty={setCounty}
                appt={appt}
                setAppt={setAppt}
              />
            }
          />
          <Route path='/routing' element={<ClientSideRouting />} />
          <Route path='/navClientList' element={<NavsClients />} />
          <Route path='/calendar' element={<ScheduleCard />} />
          <Route path='/government' element={<GovReport />} />
          <Route path='/government:id' element={<GovReport />} />
          <Route path='/gov-review' element={<GovReview />} />
          <Route path='/navigator-calendar' element={<NavigatorCalendar />} />
          <Route path='/schedules' element={<SuperAdminSchedule />} />
          <Route path='/navigator-schedules' element={<NavigatorsSchedule />} />
          <Route path='/locations' element={<Locations />} />
          <Route path='/shift' element={<AddShiftForm />} />
          <Route path='/deleted-users' element={<DeletedUsers />} />
          <Route path='/call-list' element={<CallListPage />} />
          <Route path='/navigator-call-list' element={<CallListNavigator />} />
          <Route path='/call-list-specific' element={<CallListSpecific />} />
          <Route path='/call-list-specific:uploadId' element={<CallListSpecific />} />
          <Route path='/nav-call-list-specific' element={<NavCallListSpecific />} />
          <Route path='/nav-call-list-specific:uploadId' element={<NavCallListSpecific />} />
          <Route path='/counties' element={<Counties />} />
          <Route path='/mobile-outreach' element={<MobileOutreachPage />} />
          <Route path='/mobile-outreach-report:id' element={<MobileOutreachLIReport />} />
          <Route path='/mobile-outreach-profile:id' element={<MobileOutreachProfile />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;

/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchedApptsClient from '../pages/SearchedApptsClient';

// used for client side appointments

const MakeAppointmentClient = ({ typeArr, setTypeArray, county,
  setCounty, appt }) => {
  const navigate = useNavigate();

  const navigateToSearch = () => {
    navigate('/searchresults');
  };

  const [counties, setCounties] = useState([]);

  useEffect(() => {
    const headers = { 'Content-Type': 'application/json'
    };
    fetch(`${window.env.REACT_APP_API_URL}`+
    'api/allAppointmentCounties',
    { headers } )
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCounties(data);
      });
  }, []);


  function addNewItem(type) {
    if (typeArr.includes(type)) {
      for ( let i = 0; i < typeArr.length; i++) {
        if ( typeArr[i] === type) {
          typeArr.splice(i, 1);
          (typeArr);
        }
      }
    } else {
      typeArr.push(type);
      setTypeArray(typeArr);
    }
  }

  return (
    <div>
      <form
        onSubmit={navigateToSearch}>
        <div className="d-flex justify-content-center">
          <div className="card cardShadow mt-5 w-25">
            <div className="card-body">
              <div className="h4 mb-4">Find help near you</div>
              <div className="row mb-2">
                <div className="ms-3 p col-5">Select your county</div>
              </div>
              <div className="row">
                <div className="col-10">
                  <select
                    required
                    className='form-select form-control ms-4 w-100'
                    aria-label='select county'
                    data-testid='apptCounty'
                    onChange={e => setCounty(e.target.value)}
                  >
                    {counties.length == 0 ?
                      <option value=''>No appointments currently available
                      </option> :
                      <option value=''>Select County</option>
                    }
                    {counties.map((county, id) =>
                      <option key={id} value={county}>{county}</option>
                    )}
                  </select>
                </div>

                <div className="p ms-3 mt-4">Show locations which offer</div>
                <div className='typeCheckList mb-3 m-3'>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='inlineOptions1'
                      value='In-Person'
                      defaultChecked
                      onChange={e => addNewItem(e.target.value)}
                      data-testid='inPersonCheck'
                    />
                    <label className='form-check-label'
                      htmlFor='inlineCheckbox1'>
                      In-Person
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='inlineOptions2'
                      value='Telephone'
                      defaultChecked
                      onChange={e => addNewItem(e.target.value)}
                      data-testid='telephoneCheck'
                    />
                    <label className='form-check-label'
                      htmlFor='inlineCheckbox2'>
                      Telephone
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='inlineOptions3'
                      value='Virtual'
                      defaultChecked
                      onChange={e => addNewItem(e.target.value)}
                      data-testid='virtualCheck'
                    />
                    <label className='form-check-label'
                      htmlFor='inlineCheckbox3'>
                      Virtual
                    </label>
                  </div>
                </div>
                <div className="ms-3">
                  <button type="submit" className="btn btn-warning cardShadow">
                    SEARCH APPOINTMENTS</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MakeAppointmentClient;

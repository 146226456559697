/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import { Fade } from 'react-bootstrap';
import ApptConfirmation from '../pages/ApptConfirmation';
import moment from 'moment';

// for client side appointments

function ConfirmApptModal({ appt, prefType, clientFn, clientLn, email, phone }) {
  return (
    <div className="modal fade"
      id={'confirmApptModal' + appt.id}
      tabIndex="-1"
      aria-labelledby='confirmApptModal'
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content confirmApptModal p-3">
          <div className="modal-body">
            <div className='d-flex justify-content-end'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className="row">
              <div className="h4">
                Your appointment is now scheduled.
              </div>
            </div>
            <div className="row text-secondary mb-3">
              You will receive phone and email confirmation
            </div>
            <div className="row">
              <div className="row fs-4 ms-3 mt-5">
                {clientFn} {clientLn}
              </div>
              <div className='ms-3'>{email}</div>
              <div className='ms-3'>{phone}</div>
            </div>
            <hr />
            <div className="row ms-2">
              <div className="row h4 ms-3">
                <div className="col-5 ms-3 h5">
                  {moment(appt.appt_date).format('MMM')}
                  {moment(appt.appt_date).format('DD')}
                </div>
              </div>
              <div className='col-8'>{appt.appt_time}&nbsp;
                <span className=' h3 badge badge-blue m-1
                       rounded-pill'>
                  {prefType}</span> appointment</div>
              <div className="col-4">
                <img src="../images/wheelchair.svg" alt="" />&nbsp;&nbsp;
                <img src="../images/blind.svg" alt="" />&nbsp;&nbsp;
                <img src="../images/deaf.svg" alt="" />
              </div>
            </div>
          </div>
          {/* <div className="row fs-4 ms-3 mt-5">
            {clientFn} {clientLn}
          </div>
          <div className="row h4 ms-3">
            {appt.appt_time} {prefType} Appointment
          </div>
          <hr />
          <div className='ms-3'>{email}</div>
          <div className='ms-3'>{phone}</div> */}
        </div>

      </div>
    </div>

  );
}

export default ConfirmApptModal;

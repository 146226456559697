/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { getNavigatorName } from '../services/AppointmentService';


const Navigator = ({ id }) => {
  const [fn, setFn] = useState('');
  const [ln, setLn] = useState('');

  useEffect(() => {
    if (id != 0) {
      (async () => {
        const navNameRes = await getNavigatorName(id);
        setFn(navNameRes.first_name);
        setLn(navNameRes.last_name);
      })();
    } else {
      setFn('All');
      setLn('navigators');
    }
  }, [id]);

  return (
    <span className=''>
      {`${fn} ${ln}`}
    </span>
  );
};

export default Navigator;


/* eslint-disable react/prop-types */
import React from 'react';


// For client side appointments
function Appointments({ newAppointment, allAppointments }) {
  return (
    <>
      <div className='row text-center mb-1'>
        <div className='col'>
          <div className='h6'>My Appointments</div>
        </div>
        <div className='col'>
          <a className='btn link-text col p-0'
            data-bs-toggle='modal'
            data-bs-target='#addAppointmentModal'>
            <i className="bi bi-plus-lg"></i> Add New Appt. </a>
        </div>
      </div>
      <div className='appointment-list'>
        <ul>
          {allAppointments.map((appt, id) => (
            <li key={id} className='mb-4'>
              <div className='row'>
                <div className='appointmentName col ms-1'>
                  Appointment - {appt.title}
                </div>
                <div className='col text-center'>
                  <button type="button"
                    className="btn btn-primary btn-sm w-50">Start</button>
                </div>
              </div>
              <div className='appointmentTime'>
                {appt.start.toLocaleTimeString()}
                -{appt.end.toLocaleTimeString()}
              </div>
            </li>
          ))}

        </ul>
      </div>
    </>
  );
}

export default Appointments;
